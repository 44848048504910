













































































import { CodeWithInfoTFA, InfoTFA } from '@/store/modules/profile/types'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { getDateWithDifference } from '@/api/v3/DataGenerator'
import * as actionTypes from '@/store/actionTypes'
import AuthScreen from './components/AuthScreen.vue'
import ModalHeader from './components/ModalHeader.vue'
import type CodeInputStep from './components/CodeInputStep.vue'
import { format } from 'quasar'

const { capitalize } = format

@Component({
  name: 'ChangeEmail',
  components: {
    ModalHeader,
    AuthScreen,
    EmailStep: () => import('./components/EmailStep.vue'),
    SuccessStep: () => import('./components/SuccessStep.vue'),
    CodeInputStep: () => import('./components/CodeInputStep.vue'),
  },
})
export default class ChangeEmail extends Vue {
  @Prop({
    type: Boolean,
    required: true,
  })
  readonly isOpened!: boolean

  $refs!: {
    CodeInput: CodeInputStep;
  }

  login = false

  loading = false

  changeSuccess = false
  emailStep = true

  email = ''
  emailErr = ''

  pass = ''

  codeLength = 4
  codeErr = ''

  codeTimeout = -1
  codeInterval: number | null = null

  get header () {
    if (this.changeSuccess) { return this.$t('auth.twoFactorAuth.mailChangedTitle') }
    if (!this.emailStep) return this.$t('auth.twoFactorAuth.codeInputTitle')
    return this.login
      ? this.$t('auth.twoFactorAuth.mailStepTitle')
      : this.$t('auth.twoFactorAuth.title')
  }

  get showControls () {
    return !this.loading || this.emailStep
  }

  get btnOptions () {
    const classString = this.changeSuccess || !this.emailStep
      ? 'secondary-btn'
      : 'primary-btn'

    const action = this.changeSuccess
      ? this.close
      : this.emailStep
        ? this.setEmail
        : this.setEmailStep

    const label = this.changeSuccess
      ? this.$t('auth.twoFactorAuth.backToSettings')
      : this.emailStep
        ? capitalize(this.$t('common.next').toString())
        : this.$t('common.back')

    const disable = !this.email && this.emailStep
    return {
      classString,
      action,
      label,
      disable,
    }
  }

  setEmailStep () {
    this.emailStep = true
  }

  async confirmCode (value: string) {
    this.loading = true
    try {
      await this.$store.dispatch(actionTypes.TFA_CONFIRM_EMAIL, {
        password: this.pass,
        email: this.email,
        code: value,
      })
      this.changeSuccess = true
    } catch (e) {
      this.codeErr = e.details?.code ?? e.error
      this.$refs.CodeInput.focus()
    }

    this.loading = false
  }

  emailInput (value: string) {
    this.email = value
    this.emailErr = ''
  }

  async setEmail (withEmail = true) {
    this.loading = true
    this.emailErr = ''
    this.codeErr = ''
    const payload: { password: string; email?: string } = {
      password: this.pass,
      email: withEmail ? this.email : undefined,
    }

    try {
      /**
       * it can technically return InfoTFA, but not in this case
       * TODO: refactor! two actions or overloads
       */
      const r: CodeWithInfoTFA | InfoTFA = await this.$store.dispatch(
        actionTypes.TFA_SET_EMAIL,
        payload,
      )
      if (withEmail) {
        const typedR: CodeWithInfoTFA = r as CodeWithInfoTFA
        this.codeLength = typedR.codeLength

        this.codeTimeout = (
          new Date(typedR.nextCodeAt).getTime() -
          getDateWithDifference('ts')
        ) / 1000

        this.codeInterval = this.codeInterval || setInterval(() => {
          if (this.codeTimeout >= 0) {
            this.codeTimeout--
          } else {
            this.codeInterval && clearInterval(this.codeInterval)
            this.codeInterval = null
          }
        }, 1000)
      } else {
        this.changeSuccess = true
      }
      this.emailStep = false
    } catch (e) {
      this.emailErr = e.details?.email ?? e.error
      this.codeErr = e.details?.email ?? e.error
    }
    this.loading = false
  }

  // Close modal
  close () {
    this.$emit('close')
  }

  /**
   * * Emitted by AuthScreen
   */
  auth (pass: string) {
    this.login = true
    this.pass = pass
  }
}
