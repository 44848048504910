import { render, staticRenderFns } from "./ChangeEmail.vue?vue&type=template&id=57262f2f&scoped=true&"
import script from "./ChangeEmail.vue?vue&type=script&lang=ts&"
export * from "./ChangeEmail.vue?vue&type=script&lang=ts&"
import style0 from "./ChangeEmail.vue?vue&type=style&index=0&id=57262f2f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57262f2f",
  null
  
)

export default component.exports
import {QDialog,QCard,QBtn,QSpinnerDots} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QBtn,QSpinnerDots})
